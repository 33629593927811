import React, { useContext, useEffect, useRef, useState } from "react"
import { AccountContext } from "../../../context/account"
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Select,
  Option,
  Textarea,
  Switch,
  Radio,
  Collapse,
} from "@material-tailwind/react";
import { Controller, useForm } from "react-hook-form";

import StoreServiceController from '../../../services/store/service'
import { toast } from "react-hot-toast";
import { useDebounce } from "../../../utils/custom-hook";
import { Bars3Icon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

function AddSingleService({
  categories,
  submitRef,
  onSubmit,
  open
}) {
  const [oldPrice, setOldPrice] = useState<any>(null)
  const { handleSubmit, formState: { errors }, control, watch, setValue, reset } = useForm<{
    name: string,
    category: string,
    description: string,
    availableFor: string,
    onlineBooking: any
    team: any[],
    price: number,
    duration: any,
    priceType: any,
  }>()

  const watchAll = watch()

  useEffect(() => {
    if (watchAll.priceType === 'free') {
      setOldPrice(watchAll.price)
      setValue('price', 0)
    }
    else {
      setValue('price', oldPrice)
    }
  }, [watchAll.priceType])

  useEffect(() => {
    if (!open)
      reset()
  }, [open])

  return (<>
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          <Controller
            name='name'
            control={control}
            rules={{
              validate: (e: string) => {
                if (!e)
                  return 'This field is required'
              }
            }}
            render={({ field: { value, onChange } }) => {
              return <Input
                className="capitalize"
                variant="outlined" label="Service name" value={value} onChange={onChange} />
            }}
          />
          <div className="text-red-500 text-sm">{
            errors?.name?.message
          }</div>
        </div>

        <div className="my-4">
          <Controller
            name='category'
            control={control}
            rules={{
              validate: (e: string) => {
                if (!e)
                  return 'This field is required'
              }
            }}
            render={({ field: { value, onChange } }) => {
              return <Select variant='outlined' label="Category" onChange={onChange} value={value}>
                {
                  categories?.map((category: any, index: number) => <Option value={category.id}>
                    <span className="capitalize">{category.name}</span></Option>)
                }
              </Select>
            }}
          />
          <div className="text-red-500 text-sm">{
            errors?.category?.message
          }</div>
        </div>

        <div className="my-4">
          <Controller
            name='description'
            control={control}
            render={({ field: { value, onChange } }) => {
              return <Textarea
                className="capitalize"
                variant="outlined" label="Service description" value={value} onChange={onChange} />
            }}
          />
        </div>

        <div className="my-4">
          <Controller
            name='availableFor'
            control={control}
            defaultValue="all"
            render={({ field: { value, onChange } }) => {
              return <Select variant='outlined' label="Available for" value={value} onChange={onChange}>
                <Option value='all'> Everyone</Option>
                <Option value='male'> Male</Option>
                <Option value='female'> Female</Option>
              </Select>
            }}
          />
        </div>

        <div className="my-4">
          <Controller
            name='onlineBooking'
            control={control}
            defaultValue={true}
            render={({ field: { value, onChange } }) => {
              return <Switch
                label='Online booking'
                onChange={onChange}
                value={value}
                defaultChecked />
            }}
          />
        </div>

        <div className="grid grid-cols-3 my-4 gap-2">
          <div className="">
            <Controller
              name='duration'
              control={control}
              defaultValue="30"
              render={({ field: { value, onChange } }) => {
                return <Select variant='outlined' label="Duration" value={value} onChange={onChange}>
                  <Option value='15'> 15min</Option>
                  <Option value='30'> 30min</Option>
                  <Option value='45'> 45min</Option>
                  <Option value='60'> 60min</Option>
                  <Option value='75'> 75min</Option>
                  <Option value='90'> 90min</Option>
                  <Option value='105'> 105min</Option>
                  <Option value='120'> 120min</Option>
                </Select>
              }}
            />
          </div>

          <div className="">
            <Controller
              name='priceType'
              control={control}
              defaultValue="fixed"
              render={({ field: { value, onChange } }) => {
                return <Select variant='outlined' label="Price type" value={value} onChange={onChange}>
                  <Option value='free'> Free</Option>
                  <Option value='fixed'> Fixed</Option>
                </Select>
              }}
            />
          </div>

          <div className="">
            <Controller
              name='price'
              control={control}
              defaultValue={0}

              render={({ field: { value, onChange } }) => {
                return <Input
                  disabled={watchAll.priceType === 'free'}
                  className="capitalize"
                  variant="outlined" label="Price" value={value} onChange={(e) => {
                    try {
                      const temp = +e.target.value?.replaceAll(',', '')
                      if (!isNaN(temp))
                        onChange(temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                    }
                    catch (e) {
                      return
                    }
                  }}
                />
              }}
            />
          </div>
        </div>

        <button type="submit" ref={submitRef} />
      </form>
    </React.Fragment>
  </>)
}

function AddPackageService({
  categories,
  submitRef,
  onSubmit,
  open,
  selectedServices,
  setSelectedServices
}) {
  const [oldPrice, setOldPrice] = useState<any>(null)
  const { handleSubmit, formState: { errors }, control, watch, setValue, reset } = useForm<{
    name: string,
    category: string,
    description: string,
    availableFor: string,
    onlineBooking: any
    team: any[],
    value: string,
    priceType: any,
  }>()
  const [openSelectService, setOpenSelectService] = useState<boolean>(false);

  const watchAll = watch()

  useEffect(() => {
    switch (watchAll.priceType) {
      case 'free':
        setOldPrice(watchAll.value)
        setValue('value', '0');
        break;

      case 'all':
        setOldPrice(watchAll.value)
        setValue('value', selectedServices?.reduce((a: number, p: any) => {
          return a + +p?.price
        }, 0)?.toString());
        break;

      default:
        setValue('value', oldPrice?.toString())
    }
  }, [watchAll.priceType])

  useEffect(() => {
    if (!open)
      reset()
  }, [open])

  useEffect(() => {
    if (watchAll.priceType === 'all')
      setValue('value', selectedServices?.reduce((a: number, p: any) => {
        return a + +p?.price || 0
      }, 0)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","))

  }, [selectedServices])

  return (<>
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          <Controller
            name='name'
            control={control}
            rules={{
              validate: (e: string) => {
                if (!e)
                  return 'This field is required'
              }
            }}
            render={({ field: { value, onChange } }) => {
              return <Input
                className="capitalize"
                variant="outlined" label="Package name" value={value} onChange={onChange} />
            }}
          />
          <div className="text-red-500 text-sm">{
            errors?.name?.message
          }</div>
        </div>

        <div className="my-4">
          <Controller
            name='category'
            control={control}
            rules={{
              validate: (e: string) => {
                if (!e)
                  return 'This field is required'
              }
            }}
            render={({ field: { value, onChange } }) => {
              return <Select variant='outlined' label="Category" onChange={onChange} value={value}>
                {
                  categories?.map((category: any, index: number) => <Option value={category.id}>
                    <span className="capitalize">{category.name}</span></Option>)
                }
              </Select>
            }}
          />
          <div className="text-red-500 text-sm">{
            errors?.category?.message
          }</div>
        </div>

        <div className="my-4">
          <Controller
            name='description'
            control={control}
            render={({ field: { value, onChange } }) => {
              return <Textarea
                className="capitalize"
                variant="outlined" label="Package description" value={value} onChange={onChange} />
            }}
          />
        </div>

        <div className="my-4">
          <Controller
            name='availableFor'
            control={control}
            defaultValue="all"
            render={({ field: { value, onChange } }) => {
              return <Select variant='outlined' label="Available for" value={value} onChange={onChange}>
                <Option value='all'> Everyone</Option>
                <Option value='male'> Male</Option>
                <Option value='female'> Female</Option>
              </Select>
            }}
          />
        </div>

        <div className="my-4">
          <Controller
            name='onlineBooking'
            control={control}
            defaultValue={true}
            render={({ field: { value, onChange } }) => {
              return <Switch
                label='Online booking'
                onChange={onChange}
                value={value}
                defaultChecked />
            }}
          />
        </div>

        <div className="my-4 rounded border p-2 bg-gray-200">
          <div className="font-medium text-lg">
            Services
          </div>

          {
            selectedServices?.map((p: any, index: number) => {
              return <React.Fragment key={index}>
                <div className={`grid grid-cols-10 my-3 cursor-pointer p-2 rounded bg-gray-400 text-white hover:bg-gray-500`}>
                  <div className="flex gap-2 items-center col-span-5">
                    <Bars3Icon className="w-6 h-6" />
                    <span className="capitalize text-lg">{p?.name}</span>
                  </div>
                  <div className="justify-end text-lg col-span-4 flex items-center text-white">
                    {
                      p?.price
                    }
                  </div>
                  <div className="text-right ">
                    <Button onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      let temp = [...selectedServices]
                      setSelectedServices(temp?.filter(t => t.id !== p.id))
                    }} variant="text" className="hover:bg-transparent focus:bg-transparent bg-transparent">
                      <XMarkIcon className="w-6 h-6 text-red-200" />
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            })
          }
          <div className="grid grid-cols-10 ">
            <div className="col-span-5">
              <Button size="sm" className="text-blue-500 flex items-center gap-3" variant="text" onClick={() => setOpenSelectService(true)}>
                <PlusCircleIcon strokeWidth={2} className="h-5 w-5" /> Add service
              </Button>
            </div>
            {
              selectedServices?.length > 0 && <div className="text-right py-2 font-medium text-lg col-span-4" >
                Total {
                  selectedServices?.reduce((a: number, p: any) => {
                    return a + +p?.price || 0
                  }, 0)
                }
              </div>
            }

          </div>
        </div>

        <div className="grid grid-cols-1 my-4 gap-2">
          <div className="">
            <Controller
              name='priceType'
              control={control}
              defaultValue="all"
              render={({ field: { value, onChange } }) => {
                return <Select
                  variant='outlined'
                  label="Price type"
                  value={value}
                  onChange={onChange}>
                  <Option value='all'> All</Option>
                  <Option value='free'> Free</Option>
                  <Option value='fixed'> Fixed</Option>
                  <Option value='percent'> Percent</Option>
                </Select>
              }}
            />
          </div>

          <div className="">
            <Controller
              name='value'
              control={control}
              rules={{
                validate: (e: string) => {
                  const temp = +e?.replaceAll(',', '');
                  console.log("🚀 ~ file: AddService.tsx:447 ~ temp:", temp, watchAll.priceType)

                  switch (watchAll.priceType) {
                    case 'fixed':
                      if (temp > selectedServices?.reduce((a: number, p: any) => {
                        return a + +p?.price || 0
                      }, 0))
                        return 'Price is not valid'
                      break;

                    case 'percent':
                      if (temp > 100)
                        return 'Percent is not valid'
                      break;
                  }

                  return;
                }
              }}
              render={({ field: { value, onChange } }) => {
                return <Input
                  disabled={watchAll.priceType === 'free' ||
                    watchAll.priceType === 'all'}
                  className="capitalize"
                  variant="outlined"
                  label="Value"
                  value={value}
                  onChange={(e) => {
                    try {
                      const temp = +e.target.value?.replaceAll(',', '')
                      if (!isNaN(temp)) {
                        onChange(temp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                      }

                    }
                    catch (e) {
                      return
                    }
                  }}
                />
              }}
            />
          </div>
          <div className="text-red-500 text-sm">{
            errors.value?.message}</div>
        </div>

        <button type="submit" ref={submitRef} />
      </form>

      <SelectService
        categories={categories}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        open={openSelectService}
        onClose={setOpenSelectService}
      />
    </React.Fragment >
  </>)
}

function SelectService({
  selectedServices,
  setSelectedServices,
  categories,
  open,
  onClose
}) {
  const [openCategory, setOpenCategory] = useState(categories[0]?.id)
  const [tempSelectedServices, setTempSelectedServices] = useState<any>(selectedServices)

  useEffect(() => {
    setTempSelectedServices([...selectedServices])
  }, [selectedServices])

  return (
    <React.Fragment>
      <Dialog size="xs" open={open} handler={() => { }}>
        <DialogHeader>Select service</DialogHeader>
        <DialogBody divider>
          {
            categories?.map((category: any, index: number) => {
              if (category?.products?.filter(p => !p.package)?.length)
                return <React.Fragment key={index}>
                  <Button
                    style={{
                      color: category?.color
                    }}
                    variant="text" className="px-0 bg-transparent outline-none focus:bg-transparent" onClick={() => {
                      if (openCategory === category?.id)
                        return setOpenCategory('')
                      setOpenCategory(category?.id)
                    }}>{category?.name} </Button>
                  <Collapse open={openCategory === category?.id}>
                    {
                      category?.products?.map((p: any, index: number) => {
                        if (!p.package)
                          return <React.Fragment key={index}>
                            <div className={`grid grid-cols-2 my-2 cursor-pointer hover:bg-gray-100 p-2 rounded ${tempSelectedServices?.find(i => i?.id === p?.id) && `bg-gray-600 text-white hover:bg-gray-500`}`}>
                              <div className="flex gap-2 items-center " onClick={(e) => {
                                if (tempSelectedServices?.find(i => i?.id === p?.id))
                                  return
                                e.preventDefault()
                                e.stopPropagation()
                                setTempSelectedServices([...tempSelectedServices, p])
                              }}>
                                <Bars3Icon className="w-6 h-6" />
                                <span className="capitalize text-lg">{p?.name}</span>
                              </div>
                              {
                                tempSelectedServices?.find(i => i?.id === p?.id) && <div className="text-right">
                                  <Button onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    let temp = [...tempSelectedServices]
                                    setTempSelectedServices(temp?.filter(t => t.id !== p.id))
                                  }} variant="text" className="hover:bg-transparent focus:bg-transparent bg-transparent">
                                    <XMarkIcon className="w-6 h-6 text-red-200" />
                                  </Button>
                                </div>
                              }

                            </div>
                          </React.Fragment>
                      })
                    }
                  </Collapse>
                </React.Fragment>
            })
          }
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            // onClick={}
            onClick={() => onClose(false)}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
          <Button variant="gradient" color="blue" onClick={() => {
            setSelectedServices([...tempSelectedServices])
            onClose(false)
          }}>
            <span>Save</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment >
  )
}


export default function AddServiceModal({
  open = false,
  categories = [],
  onClose,
  onSucceed,
}) {
  const { store } = useContext(AccountContext)
  const [creating, setCreating] = useState<boolean>(false)
  const submitRef = useRef<any>(null)
  const [serviceType, setServiceType] = useState<string>('')
  const [selectedServices, setSelectedServices] = useState<any[]>([]);

  const onSubmit = async (e) => {
    try {
      setCreating(true)
      switch (serviceType) {
        case 'single':
          e.price = e?.price?.toString()?.replaceAll(',', '')
          await StoreServiceController.createService(
            e.category,
            e.name,
            +e.price,
            +e.duration,
            e.description,)
            .catch(e =>
              toast.error('Something is error')
            )
            .then(e => {
              if (e?.status === 1) {
                onSucceed()
                return toast.success(e.message)
              }

              return toast.error(e.message)
            })
            .finally(() => {
              setCreating(false)
            })
          break;
        case 'package':
          e.value = e?.value?.toString()?.replaceAll(',', '')
          await StoreServiceController.createPackage(
            e.category,
            e.name,
            +e.value,
            e?.priceType,
            selectedServices?.map((p: any) => {
              return p.id as string
            }),
            e.description,)
            .catch(result =>
              toast.error('Something is error')
            )
            .then((result: any) => {
              if (result?.status === 1) {
                onSucceed()
                return toast.success(result.message)
              }

              return toast.error(result.message)
            })
            .finally(() => {
              setCreating(false)
            })
      }

    } finally {
      setCreating(false)
    }
  }

  return (
    <React.Fragment>
      <Dialog open={open} handler={() => { }}>
        <DialogHeader>Add service</DialogHeader>
        <DialogBody divider>
          <Radio
            onChange={() => setServiceType('single')}
            label='Single Service' name='type' value='single' id='sing-service' />
          <Radio
            onChange={() => setServiceType('package')}
            label='Package Service' name='type' value='package' id='package-service' />
          {
            serviceType === 'single' && <AddSingleService
              categories={categories}
              submitRef={submitRef}
              onSubmit={onSubmit}
              open={open}
            />
          }
          {
            serviceType === 'package' && <AddPackageService
              categories={categories}
              submitRef={submitRef}
              onSubmit={onSubmit}
              open={open}
              setSelectedServices={setSelectedServices}
              selectedServices={selectedServices}
            />
          }

        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={onClose}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
          <Button variant="gradient" color="blue" disabled={creating} onClick={() => {
            submitRef.current?.click();
          }}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  )
}