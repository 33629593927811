import React, { useContext, useRef, useState } from "react"
import { AccountContext } from "../../../context/account"
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { Controller, useForm } from "react-hook-form";
import StoreController from '../../../services/store'
import { toast } from "react-hot-toast";

export default function AddCategoryModal({
  open = false,
  onSucceed,
  onClose
}) {
  const { store } = useContext(AccountContext)
  const submitRef = useRef<any>(null)
  const { control, handleSubmit, formState: { errors }, reset } = useForm<{
    description: string,
    color: string,
    name: string
  }>()
  const [creating, setCreating] = useState<boolean>(false)

  const onSubmit = async (e: any) => {
    setCreating(true)
    await StoreController.addCategory(
      e.name,
      e.description,
      e.color,
      store?.id
    ).then(e => {
      if (e?.status === 1) {
        onSucceed()
        reset()
        return toast.success(e.message)
      }

      return toast.error(e.message)
    }).catch(e => {
      toast.error('Something is error')
    }).finally(() => {
      setCreating(false)
    })
  }

  return (
    <React.Fragment>
      <Dialog open={open} handler={() => { }} size="xs">
        <DialogHeader>Add Category</DialogHeader>
        <DialogBody divider>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='name'
              control={control}
              rules={{
                validate: (e: string) => {
                  if (!e)
                    return `This field is required`
                }
              }}
              render={({ field: { value, onChange } }) => {
                return <div className="my-4">
                  <Input
                    variant="outlined"
                    label="Category name"
                    className="capitalize"
                    value={value}
                    onChange={onChange} />
                  <div className="text-sm text-red-300">{
                    errors.name?.message
                  }</div>
                </div>
              }}
            />

            <Controller
              name='color'
              control={control}
              defaultValue="#2576b91a"
              render={({ field: { value, onChange } }) => {
                return <div className="my-4">
                  <div>Color</div>
                  <input value={value} onChange={onChange} type='color' defaultValue={'#2576b91'} />
                </div>
              }}
            />

            <Controller
              name='description'
              control={control}
              render={({ field: { value, onChange } }) => {
                return <div className="my-4">
                  <Textarea variant="outlined" label="Category description" value={value} onChange={onChange} />
                </div>
              }}
            />
            <button type="submit" ref={submitRef} />
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => { onClose() }}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
          <Button variant="gradient" color="blue" onClick={() => { setCreating(true); submitRef.current?.click() }}>
            <span>Save</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  )
}