import React, { useContext, useEffect } from "react"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import Layout from "../../components/layout/store/Layout"
import ServicesView from "../../components/views/dashboard/store/services"
import AppointmentsPage from "./appointments"
import { AccountContext } from "../../context/account"

const ServicePage = () => {
  const { store, getStore } = useContext(AccountContext)
  useEffect(() => {
    if (!store)
      getStore()
  }, [])
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <ServicesView />
      </Layout>
    </DndProvider>
  )
}

export default ServicePage
