import React, { useContext, useEffect, useState } from "react"
import GearIcon from "../../../fundamentals/icons/gear-icon"
import SearchIcon from "../../../fundamentals/icons/search-icon"
import Select from "../../../molecules/select/next-select/select"
import { arrayMove, rectSortingStrategy, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { closestCenter, DndContext, DragEndEvent, DragMoveEvent, PointerSensor, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import ListIcon from "../../../fundamentals/icons/list-icon";
import StackIcon from "../../../fundamentals/icons/stack-icon";
import { AccountContext } from "../../../../context/account";
import StoreController from '../../../../services/store'
import DragAndDrop from "../../../dnd";
import { Bars3Icon } from "@heroicons/react/24/outline";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import AddServiceModal from "../../../modal/service/AddService";
import AddCategoryModal from "../../../modal/category/AddCategory";
import { Button, Menu, MenuHandler, MenuItem, MenuList } from "@material-tailwind/react";

interface SortableItemProps {
  id: string;
  item: any;
}
export const SortableItem: React.FC<SortableItemProps> = ({ id, item }) => {
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
    // border: "1px solid red",
    // marginTop: "10px"
    cursor: "pointer",
    padding: 10,
    margin: 10,
    flex: 1
  };
  return (
    <div ref={setNodeRef} {...listeners} style={styles} >
      <div className="grid grid-cols-12 p-4 border border-l-[5px] border-blue-200 my-2">
        <div className="col-span-7">
          <div className="font-bold flex items-center">
            <div className="mr-3"><StackIcon size={20} /> </div>
            {item.title}
          </div>
        </div>

        <div className="col-span-3 flex items-center">
          <div className="text-gray-500 text-md ">{
            item.time
          }min</div>
        </div>

        <div className="col-span-2 flex items-center justify-end">
          A<div className="text-gray-500 text-md ">{
            item.amount
          }$</div>
        </div>
      </div>


    </div>
  );
};

const ServicesView = () => {
  const { store } = useContext(AccountContext)
  const [categories, setCategories] = useState<any[]>([]);
  const [openAddCategory, setOpenAddCategory] = useState<boolean>(false);
  const [openAddService, setOpenAddService] = useState<boolean>(false);
  const [render, setRender] = useState(false)

  const getCategories = async () => {
    const response = await StoreController.getCategories(store?.id)
    const temp = response?.result?.categories;

    setCategories(temp)
  }

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result?.destination?.droppableId === result?.source?.droppableId) {
      return;
    }

    const temp: any[] = JSON.parse(JSON.stringify(categories))
    const indexSource = temp?.findIndex(e => e.id === result?.source?.droppableId)
    const indexDestination = temp?.findIndex(e => e.id === result?.destination?.droppableId)
    const tempProduct = temp[indexSource]?.products?.find((e: any) => e.id === result?.draggableId)

    // con
    temp[indexSource].products = temp[indexSource]?.products?.filter(e => e.id !== tempProduct?.id)
    temp[indexDestination].products.push(tempProduct)
    setCategories(temp)

    await StoreController.updateProductCategory(
      result?.destination?.droppableId,
      store?.id,
      result?.draggableId).catch(e => {
        getCategories()
        return toast.error('Something is error')
      })
      .then(e => {
        if (+e?.status !== 1) {
          getCategories()
          return toast.error(e?.message)
        }
        return toast.success(e.message)
      })

  };

  const renderColor = (color) => {
    return {
      bl: `border-l-[${color}]`
    }
  }

  useEffect(() => {
    if (!store)
      return;

    getCategories().then(() => {
      setRender(true)
    })
  }, [store])

  return (<>{
    render && <React.Fragment>

      <div className="grid grid-cols-12 gap-4 px-20">
        <div className="col-span-8 justify-between">
          <div>
            <div className="font-extrabold text-xl">Services menu</div>
          </div>

        </div>
        <div className="col-span-2">
          <Select placeholder='Options' size="md" options={[]}
            onChange={(e) => { console.log(e) }}
            value={null} />
        </div>
        <div className="col-span-2">
          <Menu>
            <MenuHandler>
              <Button size="lg" className='bg-black text-white rounded-md p-2 capitalize'>Add new</Button>
            </MenuHandler>
            <MenuList >
              <MenuItem onClick={() => { setOpenAddCategory(true) }}>
                Add Category</MenuItem>
              <MenuItem onClick={() => { setOpenAddService(true) }}>Add Service</MenuItem>
            </MenuList>
          </Menu>

        </div>

        <div className="grid gap-4 col-span-12">
          <DragDropContext onDragEnd={onDragEnd} >
            {
              categories.map((category: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      key={index}
                    >
                      <div className="font-bold grid grid-cols-12 px-4 border-l-[transparent] border-l-4">
                        <div className="col-span-10">
                          <div className="font-bold text-lg flex items-center">
                            <div className="mr-3">
                              <Bars3Icon className="w-4 h-8" /> </div>
                            <span className="capitalize">{category.name}</span>
                          </div>
                        </div>
                      </div>
                      <Droppable droppableId={category.id}>
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                          <div ref={provided.innerRef} className="min-h-[20px]">
                            <div className="grid gap-2">
                              {
                                category?.products?.map((product: any, productIndex: number) => {
                                  return <React.Fragment key={productIndex}>
                                    <Draggable key={product.id} draggableId={product.id + ""} index={index}>
                                      {(
                                        provided: DraggableProvided | any,
                                        snapshot: DraggableStateSnapshot
                                      ) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          <div
                                            style={{
                                              borderLeftColor: category.color
                                            }}
                                            className={`font-bold grid grid-cols-12 px-4  border border-l-4 p-2`}>
                                            <div className="col-span-7">
                                              <div className="font-medium text-sm flex items-center">
                                                <div className="mr-3">
                                                  <Bars3Icon className="w-4 h-8" color="gray" /> </div>
                                                <span className="capitalize">{product.name}</span>
                                              </div>
                                            </div>
                                            <div className="col-span-3 font-normal line-through text-gray-500">
                                              {
                                                product?.package && product?.package?.products?.reduce((a: number, b: any) => {
                                                  return a + +b?.product?.price
                                                }, 0)
                                              }
                                            </div>
                                            <div className="col-span-2 text-right">
                                              {product?.price || product?.package?.value}
                                            </div>
                                          </div>
                                        </div>)}
                                    </Draggable>

                                  </React.Fragment>
                                })
                              }
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </div>

                  </React.Fragment>
                )
              })
            }
          </DragDropContext >
        </div >

      </div >

      <AddServiceModal
        categories={categories}
        onSucceed={() => {
          setOpenAddService(false)
          getCategories()
        }}
        open={openAddService}
        onClose={() => { setOpenAddService(false) }}
      />
      <AddCategoryModal
        open={openAddCategory}
        onSucceed={() => {
          setOpenAddCategory(false)
          getCategories()
        }}
        onClose={() => {
          setOpenAddCategory(false)
        }}
      />
    </React.Fragment >

  }
  </>
  )

}

export default ServicesView
